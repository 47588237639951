.blurry {
    filter: blur(5px); 
}

.service-upgrade {

    &:not(.visa-prevalidation-upgrade) {
        position: absolute;
    }
    background-color: rgb(125, 147, 155); 
    color: white;
    border-radius: 10px;
    padding: 20px;
    font-size: large;
    left:  30%;
    top: 30%;
}
