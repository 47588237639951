.auth-container {
  display: flex;
  flex-direction: row;

  &.reset-container {
    align-items: center;
    height: 100vh;
    justify-content: center;

    .fos_user_resetting_reset {
        background: #ffffff;
        padding: 2rem;
        border-radius: 10px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        width: 100%;
        max-width: 400px;
        
        .form-group {

          .form-control {
            border: 0;
            padding: 10px;
          }
          label {
            color: $brand-secondary;
            font-size: 14px;
            font-weight: bold;
          }

          .input-group {
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid #ced4da;
            border-radius: 4px;

            .input-group-append {
                cursor: pointer;
                .fa {
                  color: #01a2db;
                  font-size: 1.2em;
                  padding: 0.5rem;
                  transition: color 0.3s ease;
                }
            }
          }
        }
       
    }
  }
}

.auth-registration-form {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.auth-logo {
  width: 200px;
  height: 200px;
  background-repeat: no-repeat;
  background-position: center;
}

.auth-information {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 500px;
}

.auth-form {
  flex: 1;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 50px 50px;

  .auth-title {
    margin-top: 30px;
    margin-bottom: 20px;
  }

  .title {
    margin-bottom: 10px;
    font-size: 48px;
    font-weight: normal;
    line-height: 78px;
  }

  .auth-modal {
    color: #01a2db;
    cursor: pointer;
  }

  .auth-info {
      color: #172e36;
      margin-top: 20px;
      margin-bottom: 5px;
  }


  .auth-action-code {
    max-width: 350px;
    .auth-action-registration {
      text-decoration: none;
      color: inherit;
    }
    .auth-action-or-list {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin: 20px 0 20px 0;

      &:before, &:after {
        content: "";
        flex: 1 1;
        border-bottom: 1px solid;
        margin: 10px;
        font-size: 12px;
        line-height: 40px;
      }
    }

  }
  .auth-action {
    max-width: 350px;

    .auth-action-or {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin: 20px 0 20px 0;

      &:before, &:after {
        content: "";
        flex: 1 1;
        border-bottom: 1px solid;
        margin: 10px;
        font-size: 12px;
        line-height: 40px;
      }
    }

    .auth-action-item {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;

    }
  }


  #auth-action-login, #auth-action-create-account {
      min-width: 150px;
      border-color: #01a2db;
      background-color: #fff;
      color: #01a2db;

      &:hover {
        color: #fff;
        background-color: #01a2db;
      }
    } 

  .auth-type {
    display: flex;
    justify-content: center;
    width: 100%;

    .auth-type-item {
      width: 250px;
      padding: 35px 25px;
      text-align: center;
      box-shadow: 0 3px 5px #EAEAEA;
      border-radius: 8px;
      font-size: 24px;
      line-height: 39px;
      user-select: none;
      margin: 20px;
    }

    input[type="radio"][checked] + .auth-type-item {
      background: $brand-secondary;
      color: $white;
    }
  }

  .reset-password, .reset-password a {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    color: $brand-dark;
    margin-top: 20px;
  }

  .login-registration-code {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    color: $brand-dark;

    a {
      margin: 10px;
      color: $brand-dark;
    }
  }

  form {
    width: 335px;
    margin: auto;

    &#register-form {
      margin-bottom: 0;
    }

    label {
      color: $brand-secondary;
      font-size: 14px;
      font-weight: bold;
    }

    input {
      &:focus, &:active {
        outline: none;
        box-shadow: none;
      }
    }

    .password-field-container {
      margin-top: 10px;
    }
  }

  .password-field-container {
    position: relative;

    .visibility-icon {
      position: absolute;
      right: 11px;
      bottom: 7px;
      cursor: pointer;
      width: 24px;
      height: 24px;
    }
  }

  #password {
    padding-right: 35px;
  }

  .form-group {
    margin-bottom: 12px;
  }

  .register-tof {
    color: $brand-dark;
    font-size: 12px;
    line-height: 24px;
    text-align: center;
    font-weight: 700;
    margin-top: 16px;
    display: none;

    a {
      font-size: 16px;
      color: $brand-dark;

      &:hover, &:active {
        color: $brand-primary;
      }
    }
  }

  .school-list {
    .school-list-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
}

.auth-img {
  flex: 1;
  background-repeat: no-repeat;
  background-position: 100% 0;
  background-size: cover;
  background-color: #fff;
}

.auth-error {
  background: radial-gradient(#172e36, transparent);
  padding: 20px;
  text-align: center;
  color: #fff;
  text-shadow:  0px 1px rgba(0, 0, 0, 0.5);

  a {
    color: #01a2db;
  }

  &.mobile {
    display: none;
  }
}


.auth-btn {
  margin-top: 25px;
  height: 48px;
  display: flex;
  align-items: center;
  padding: 0 15px;
  font-size: 16px;
  font-weight: bold;

  .auth-btn-title {
    flex: 1;
  }
}

.auth-translate {
  position: fixed !important;
  top: 0 !important;
  right: 10px;
}

@media (min-width: $screen-tablet) and (max-width: $screen-desktop - 1) {

  .auth-form {
    width: 55%
  }
  .auth-img {
    width: 45%;
  }
}

@media (max-width: $screen-tablet - 1) {
  .auth-img {
    display: none;
  }

  .auth-error {
    &.mobile {
      display: block;
      background: #172e36;
    }
  }

  .auth-form {
    width: 100%;
    min-height: 100%;
    padding-top: 80px;
    padding-left: 15px;
    padding-right: 15px;

    .auth-type {
      flex-direction: column;
      align-items: center;

      .auth-type-item {
        width: calc(100% - 50px);
        min-width: 250px;

        &:first-of-type {
          margin-right: 0;
          margin-bottom: 32px;
        }
      }
    }

    .title {
      margin-bottom: 50px;
      font-size: 36px;
      line-height: 48px;
    }
  }
}

 /* Responsive adjustments */
@media (max-width: 576px) {
    .fos_user_resetting_reset {
        padding: 1.5rem;
    }
}


.fos_user_registration_register {
  max-width: 800px;
}