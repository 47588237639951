html, body, #wrapper {
    height: 100%;
}



.main-content-col {
    position: relative;
    flex: 1;
    padding-right: $main-gutter !important;
    padding-left: $main-gutter !important;
}
.main-aside {
    background-color: #fff;
}

.main-row {
    display: flex;
}

.main-aside-col {
    flex: 0 0 300px;
    padding-right: 0 !important;
    &.pub {
        flex: 0 0 250px;
    }

    .left-aside {
        margin-top: 80px;
    }
}



/*
   Responsive
   ========================================================================== */

@media (min-width: $screen-tablet) {

}

@media (max-width: 990px) {
    .main-row {
        display: block;
    }
}

@media (min-width: $screen-desktop) {

    .container-xlg-layout {

        #main {
            min-height: 100%;
            overflow: hidden;
        }
    }
    .main-header {
        position: absolute;
        z-index: 11;
        left: 0;
        right: 0;
    }
    .main-aside-col {
        position: relative;
        z-index: 10;
    }
    .main-content-col {
        margin-top: 140px;
    }
    .main-header {

        .container {
            padding-left: 246px;
        }
    }
    .main-aside {
        position: relative;
        height: 100%;

        &:after {
            content: '';
            display: block;
            @include size(100%,4000px);
            background-color: #fff;
            position: absolute;
            left: 0;
            right: 0;
            bottom: -4000px;
        }
    }
}
@media (min-width: $screen-lg-desktop) {

    .container-xlg-layout {

        .main-row {
            margin-left: -$main-gutter-lg;
            margin-right: -$main-gutter-lg;
        }
        .main-aside-col {
            padding-left: $main-gutter-lg;
            padding-right: $main-gutter-lg;
        }
        .main-content-col {
            padding-left: $main-gutter-lg;
            padding-right: $main-gutter-lg;
        }
    }
    .main-header {

        .container {
            padding-left: 296px;
        }
    }
}
@media (min-width: $screen-xlg-desktop) {

    .container-xlg-layout {

        .container {
            width: 1470px;
        }
        .main-row {
            margin-left: -$main-gutter-xlg;
            margin-right: -$main-gutter-xlg;
        }
        .main-aside-col {
            padding-left: $main-gutter-xlg;
            padding-right: $main-gutter-xlg;
        }
        .main-content-col {
            padding-left: $main-gutter-xlg;
            padding-right: $main-gutter-xlg;

            .main-content {

                &:first-child {
                    margin-top: 25px;
                }
            }
        }
        .main-header {

            .container {
                padding-left: 376px;
            }
        }
    }
}

@media (max-width: $screen-desktop - 1) {

    .main-aside {
        border-radius: 6px;
    }
    .main-aside-col {
        margin-bottom: 25px;
    }
    .main-content-col {
        padding-left: 25px !important;
        padding-right: 15px !important;
    }

    .smartphone-alert {
        margin-top: 135px;
        & button {
            padding-right: 20px;
        }
    }
}
