.service-page-title {
  font-size: 28px;
  line-height: 38px;
  font-weight: 700;
  color: $brand-dark;
  position: relative;
  margin: 0;

  &.service-page-title-dark {
    &::before {
      content: "";
      position: absolute;
      bottom: 4px;
      left: 0;
      width: 24px;
      height: 4px;
      background-color: $brand-dark;
    }
  }

  &::before {
    content: "";
    position: absolute;
    bottom: 4px;
    left: 0;
    width: 24px;
    height: 4px;
    background-color: $body-bg;
  }

  a  {
    color: $brand-dark;

    &:visited, &:hover, &:active {
      color: inherit;
      text-decoration: none;
    }
  }

  &.page-title {
    font-size: 26px;
  }
}

.service-title {
  display: block;
  font-weight: bold;
  font-size: 30px;
  margin-top: 2px;
  color: $body-bg;


  &.service-title-dark {
    color: $brand-dark;
  }

  .service-subtitle {
    position: relative;
    text-decoration: none;
    color: inherit;

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 24px;
      height: 4px;
      background-color: $body-bg;
    }

  }
}

.service-main-content {
  background-color: $white;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 30px;

  &.first {
    margin-top: 50px;
  }
}

.service-step-container {
  .step-header {
    display: flex;
    align-items: flex-start;

    .count {
      display: block;
      margin: 12px 0 0;
    }

    .step-title {
      margin: 12px 0 0;
      flex: 1;
      color: $brand-secondary;
    }

    .service-collapse-toggle {
      width: 42px;
      height: 42px;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $white;
      border: 1px solid $brand-dark;
      border-radius: 8px;

      .fa {
        transition: transform 200ms ease-in-out;
      }

      &.open {
        .fa {
          transform: rotate(180deg);
        }
      }
    }
  }

  .service-item-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-gap: 15px;
  }
}

.status-item {
  cursor: pointer;
  border: 1px solid $brand-gray;
  border-radius: 6px;
  height: auto;
  padding: 20px;
  display: flex;
  flex-direction: column;

  &.disabled {
    .media-lock{
      span {
       float: right;
      }
    }
  }

  .service-warning {
    span {
      float: right;
      margin-right: 10px;
      color: indianred;
    }
  }

  .media {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    flex: 1;
  }

  .media-left,
  .media-body {
    vertical-align: middle;
    line-height: 1;
  }

  .media-left {
    padding: 0 10px;

    svg {
      display: inline-block;
      vertical-align: middle;

      width: 30px;
      height: 30px;

      fill: $body-bg;

      use {
        stroke: $body-bg;
      }
    }

    i {
      color: $body-bg;
    }
  }

  .media-body {
    width: 100%;
    padding-right: 10px;
  }

  .svg-creditcard,
  .svg-metro-stroke {
    stroke: $body-bg;
  }

  .svg-metro-fill {
    fill: $body-bg;
  }

  .svg-electricity,
  .svg-world,
  .svg-money,
  .svg-health {
    stroke: $body-bg;
  }
}

.si-title {
  padding-left: 2px;
}

.si-name {
  font-weight: 700;
  color: $brand-dark;
}

.si-status {
  &:active, &:hover, &:focus {
    text-decoration: none;
  }

  .unknown & {
    color: $brand-secondary;
  }

  .invalid & {
    color: $brand-red;
  }

  .pending & {
    color: $brand-yellow;
  }

  .completed & {
    color: $brand-green;
  }
}

.si-date {
  font-size: 14px;
  font-weight: 400;
  color: $brand-dark;
  display: block;
  margin-top: 8px;
}

.si-public-aid {
  color: $brand-primary;
}

.si-actions {
  width: 100%;
  background-color: #f2fafd;
  font-weight: 700;
  text-transform: uppercase;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  a {
    font-size: 15px;
    color: $brand-secondary;

    svg {
      display: inline-block;
      margin-left: 4px;
    }
  }

  .svg-arrow-right {
    stroke: $brand-secondary;
  }

  .fa {
    position: absolute;
    right: 15px;
    top: 15px;
  }
}

.tab-item { 
    padding: 20px;
    margin-bottom: 20px;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, .125);
    border-radius: 10px;

    .tab-panel-collapse .inline-editing-tinymce img {
        width: 100%;
        height: auto;
    }
}

.tab-panel-heading {
  padding: 0;
  border: 0;
}

.tab-panel-title > a, .tab-panel-title > a:active {
  text-decoration: none;
}

.tab-panel-heading a:before {
  font-family: 'Glyphicons Halflings';
  content: "\e114";
  float: right;
  transition: all 0.5s;
}

.tab-panel-heading.active a:before {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  transform: rotate(180deg);
}


.service-bottom-container {
  z-index: 11;
  display: flex;
  position: absolute;
  left: 0;
  right: 0;
  background-color: $white;
  padding-top: 30px;
  padding-bottom: 40px;
  margin: 0;

  .left {
    flex: 0 0 270px;
    padding-right: 0 !important;
  }

  .right {
    flex: 1;
    margin: 10px;
    display: flex;
    flex-direction: row;

    .service-bottom {
      display: flex;
      flex:1;
      flex-grow: 1;
      margin:20px 20px 20px 0;
      border-radius: 8px;
      padding: 20px;
      flex-direction: column;
      
      h3 {
        position: relative;
        font-size: 26px;
        line-height: 38px;
        font-weight: 700;
        margin-bottom: 10px;
      }

      h4 {
        color: inherit;
        font-size: 20px;
        line-height: 32px;
        font-weight: 700;
        margin-bottom: 14px;
      }

      .content {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        img {
          align-self: center;
          margin-top: 20px;
          margin-bottom: 20px;
          max-width: 100%;
          padding: 10px;
        }
      }
      .btn {
        color: $brand-dark;
        &:hover, &:active, &:focus {
          background-color: darken($white, 5%);
        }
      }
    }
  }

  .btn {
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    min-height: 48px;
    width: 100%;
    padding: 12px 15px;
    .fa {
      float: right;
      margin-top: 3px;
    }
  }

  .make-friends, .webinar {
    background-color: $body-bg;
    color: $box-text-color;

    &.mf-2 {
      flex: 2;
    }

    h3 {
      color: $box-text-color;
      &::before {
          content: "";
          position: absolute;
          bottom: 4px;
          left: 0;
          width: 24px;
          height: 4px;
          background-color: $box-text-color;
        }
    }

    h4 {
      color: inherit;
      font-size: 20px;
      line-height: 32px;
      font-weight: 700;
      margin-bottom: 14px;
    }

    .btn {
      color: $brand-dark;

      &:hover, &:active, &:focus {
        background-color: darken($white, 5%);
      }
    }
  }

  .life-on-campus, .event {
    background: $white;
    box-shadow: 0 3px 5px #EAEAEA;
    
    h3 {
      &::before {
        content: "";
        position: absolute;
        bottom: 4px;
        left: 0;
        width: 24px;
        height: 4px;
        background-color: $body-bg;
      }
    }

    .btn {
      border: 1px solid $brand-dark;
      color: $brand-dark;
      &:hover, &:active, &:focus {
        background-color: $body-bg;
        color: $box-text-color;
        border-color: $box-text-color;
      }
    }

    &.life-on-campus {
      .box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        background: $white;
        border: 1px solid #E4E6E8;
        border-radius: 8px;
        margin-bottom: 30px;
        padding: 20px;
        color: $brand-dark;
      }

      .box-media {
        display: flex;
      }

      .box-media-left {
        margin-right: 20px;
      }

      .hashtag {
        font-size: 14px;
        line-height: 18px;
        margin-top: 8px;

        .fa-book {
          color: #7EBE67;
        }

        .fa-user {
          color: #6A90B2;
        }

        .fa-phone {
          color: #474B4D;
        }
      }
    }
  }
}

.service-navigation-container {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .right-actions {
    display: flex;
    align-items: center;

    .row:first-child {
      margin-right: 30px;
    }
    
    .tickbox-container {
      display: flex;
      justify-content: center;
      align-items: center;
      background: #d7f5e9;
      border-radius: 6px;
      margin: 0 10px;

      .form-group {
			  margin-bottom: 0
        }
    }	
  }
}


.service-step1-video {
    padding: 20px;
    border-radius: 10px;
    background-color: $body-bg;
  
}

.rating {
  .form-group {
    margin-bottom: 10px;
  }
}

.rating-comment {
  margin: 10px;
  padding: 20px;
  border-bottom: 1px solid;

  .comment {
    display: flex;
    align-items: center;
    .profile-image {
      margin-right: 20px;
      img {
        border-radius: 50px;
        width: 30px;
      }
    }
  }

  .signature {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

.platform-rating {
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
  border-bottom: 1px solid;

  .comments-rating {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;

    .btn {
      border-radius: 6px;
    }
  }
}

 .scroll-to-bottom {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba($body-bg, 0.4);
    color: white;
    padding: 15px;
    border-radius: 5px;
    cursor: pointer;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease;

    &:hover {
        background-color: darken($color: $body-bg, $amount: 12%);
    }
}
@media (min-width: 992px) {
  .col-container {
    display: flex;
  }

  .make-friends {
    width: calc(66.6666666667% - 45px);
    margin-right: 45px;
  }
}

@media (max-width: $screen-tablet) {

  .service-bottom-container .right{
    display: block;
  }
  
  .service-navigation-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column-reverse;
    .right-actions {
        display: flex;
        align-items: center;
        flex-direction: column;

        .row:first-child {
          margin-right: 0;
        }
        
        .tickbox-container {
          display: flex;
          justify-content: center;
          align-items: center;
          background: #d7f5e9;
          border-radius: 6px;

          .form-group {
            margin-bottom: 0
          }
        }	
      }
      
    }
}


@media (max-width: 992px) {
 .service-bottom-container {
      .left {
        display: none;
      }
    }
}

@media (max-width: $screen-phone) {
  .service-bottom-container {
    .left {
      display: none;
    }

    .col-container {
      padding: 0 15px;
    }

    .life-on-campus {
      margin-right: 0;
      margin-top: 32px;
    }
  }
}

