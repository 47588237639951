.main-aside-col {
    &.pub {
        background-color: #fff;
    }
}

.header-user-dropdown {
    a {
        text-decoration: none;
    }
}

.service-notifications {
    .media {
        display: flex;
        flex-direction: column;

        .notification-body {
            border-bottom: solid;
            margin-bottom: 10px;
            display: flex;
            flex-direction: column;

            a {
                all: unset;
                color: $brand-secondary;
                margin-left: 10px;
                &:hover {
                    text-decoration: underline;
                    cursor: pointer;
                }
            }

            p {
                margin-bottom: 10px;

                &.mail {
                    &:after {
                        content: '\2709';
                        font-size: 16px;
                        margin-left: 5px;
                    }
                }
            }

        }
    }

}

.pub-container {
    height: 100%;
    background-color: #fff;
    margin-top: 140px;
    margin-right: 15px;

    .fa-youtube-play {
        color: #FF0000;
    }

    h3 {
        color: $brand-dark;
        position: relative;
        font-size: 26px;
        line-height: 38px;
        font-weight: 700;
        margin-bottom: 10px;

        &::before {
            content: "";
            position: absolute;
            bottom: 4px;
            left: 0;
            width: 24px;
            height: 4px;
            background-color: $body-bg;
        }
    }

    .pub-content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background: $body-bg;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        box-shadow: 0 3px 5px $body-bg;;
        border-radius: 8px;
        border-top-left-radius: 20px;
        border-bottom-right-radius: 20px ;
        padding: 10px;
        margin-right: 10px;
        margin-bottom: 15px;
        color: $white;
        text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
        line-height: 1.5;
        min-height: 180px;

        &.pub-bg-cover {
            background-size: contain;
        }

        h1, h2, h3, h4 {
            color: $white;
            text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
        }

        &.agent-profile {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            .agent-img img {
                border-radius: 10px;
                width: 100px !important;
                aspect-ratio: 1;
                max-width: inherit !important;
            }
            .agent-info {
                display: flex;
                flex-direction: column;
                text-align: center;
                a {
                   color: $white;
                   text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
                   font-size: smaller;
                }
            }
        }

        label {
            color: $white;
        }

        a:not(.btn) {
            color: $brand-primary;
            text-shadow: 1px 1px 2px $body-bg;
            line-height: 1.5;
            text-decoration: none;

            &:hover {
                color: $white;
                text-decoration: underline;
            }
        }
        
       .btn {
            color:$pub-button-color;
            border: none;
            background: linear-gradient(to right, $body-bg, darken($body-bg, 30%));
            &:hover, &:active, &:focus {
                color: darken($box-text-color, 69%);
                border-color: none
            }
            p {
                margin-bottom: 0px !important;
            }
        }
    }

    .pub-whatsapp {
        max-width: 150px;
        margin-left: auto;
        margin-right: auto;
    }
}

@media (max-width: 990px) {
    .pub-container {
        margin-top: 20px;
        padding: 20px;
    }
}