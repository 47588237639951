/* Show .ac-for-web and hide .ac-for-mobile on screens larger than 768px */
@media screen and (min-width: 768px) {
  .ac-for-web {
    display: block;
  }
  .ac-for-mobile {
    display: none;
  }
}

/* Show .ac-for-mobile and hide .ac-for-web on screens smaller than 768px */
@media screen and (max-width: 767px) {
  .ac-for-mobile {
    display: block;
  }
  .ac-for-web {
    display: none;
  }
}


.btn-outline-accommodation {
    border: $body-bg 1px solid;
    color:  #01A2DB;
    background-color: inherit !important;

    &:hover {
        background-color: #01A2DB !important;
    }
}

.accommodation-other-offer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 30px;
}

.accommodation-or {
    position: relative;
    text-align: center;
    margin: 0 10px;
    color:#01A2DB;

    &::before,
    &::after {
        content: '';
        position: absolute;
        top: 50%;
        width: 50px;
        height: 1px;
        background-color: #01A2DB;
    }

    &::before {
        left: -80px;
    }

    &::after {
        right: -80px;
    }
}

.residence-presentation-slider {
    width: 460px !important;    
}

.picture-container {
    position: relative;
    text-align: center;

    .fully-booked-img {
        top: 3%;
        left: 3%;
        width: 30% !important;
        position: absolute;
    }

    .fully-booked-img-sm {
        position: absolute;
        bottom: 3%;
        left: 3%;
        width: 40% !important;
    }

    .fully-booked {
        position: absolute;
        top: 20px;
        right: 5px;

        display: flex;
        flex-direction: column;

        h1 {
            color: #f6416b
        }
    }
}

.main-content {
    min-height: min-content;
    &.accommodation-main-content {
        padding: 20px;
    }
}

.accomodation-slider {
    width: 152px;
    border-radius: 6px;

    &.full {
        width: 100%;
    }

    .activities-list & {
        width: 240px;
    }

    .owl-dots {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        margin-top: 0;
        color: #fff;
    }

    .owl-stage-outer {
        border-radius: 6px;
    }

    &.owl-theme .owl-dots .owl-dot.active {
        position: relative;
        top: 1px;

        span {
            @include square(12px);
        }
    }
}

.accomodation-item {
    display: block;
    padding: 8px;
    flex: 0 0 calc(50% - 10px);
    margin: 5px;
    border-radius: 8px;
    box-shadow: 0 1px 2px $body-bg;
    &.active {
        border-color: $brand-primary;
        background-color: #effbf7;
    }

    .media,
    .media-left {
        overflow: visible;
    }
    > .media-body {
        vertical-align: middle;
    }
}

.ai-title {
    margin-bottom: 10px;
    font-weight: 700;

    &, h3 {
        font-size: 16px;
    }
    &, a {
        color: $brand-dark;
    }
}

 .fully-booked-information {
    border-radius: 6px;
    color: $text-color;
    background-color: white;
    box-shadow: 0 1px 3px $body-bg;
    cursor: pointer;
    
    &.accommodation-notification {
        &:hover {
            box-shadow: 0 1px 5px $body-bg;
        }
    }
}
.ai-adress {
    font-size: 14px;

    svg {
        display: inline-block;
        vertical-align: middle;
        line-height: 1;
        margin: -2px 2px 0 0;
    }
}
.ai-price {
    font-size: 14px;

    svg {
        display: inline-block;
        vertical-align: middle;
        line-height: 1;
        margin: -2px 2px 0 0;
    }
}

.ai-desc {
    font-size: 14px;
    margin-bottom: 5px;

    .ai-service {
        font-weight: bold;
    }

    svg {
        display: inline-block;
        vertical-align: middle;
        line-height: 1;
        margin: -2px 2px 0 0;
    }
}

.ai-item-list {
    margin-top: 10px;
}
.ai-item {
    display: inline-block;
    font-size: 14px;

    &:first-child {
        margin-right: 25px;
    }
}
.aii-name {
    font-size: 12px;
    color: lighten($text-color,20%);
}

.accomodation-card {

    a {
       &:not(.btn.btn-lg.btn-primary) {
            color: #01a2db;
        }
    }

    .accomodation-item {
        cursor: pointer;
        padding: 0;

        .ai-title {
            margin-bottom: 18px;

            &, h1 {
                font-size: 20px;
                font-weight: 400;
            }
        }
        .ai-adress,
        .ai-price,
        .ai-desc {
            margin-bottom: 3px;
            font-size: 16px;
        }
        .aii-name {
            font-size: 14px;
        }
        .aii-content {
            font-size: 16px;
        }
        .ai-item-list {
            margin-top: 25px;
        }
        .btn-lg {
            margin-top: 30px;
            font-size: 14px;
        }
        > .media-left {

            img {
                border-radius: 6px;
            }
        }
        > .media-body {
            padding-left: 20px;
        }
    }
}
.accomodation-list {
    max-height: 800px;
    overflow: auto;
    display: flex;
    flex-wrap: wrap;

    .carousel-container {
        max-height: 200px;
        
        .owl-item img {
            display: block;
            width: 100%;
            max-height: 190px;
            object-fit: cover;
            overflow: hidden;
        }
    }
}

.accomodation-form {
    display: block;
    margin: 25px 0;
    padding: 0;
    border: 2px solid $brand-primary;

    border-radius: 6px;

    .close {
        color: $brand-dark;
        opacity: 1;

        &:hover {
            opacity: .5;
        }
    }
}

.accommodation-stepper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    border-radius: 10px;
    padding: 20px;
    background-color: #fff;
    
    .c-stepper {
        width: 100%;
    }
}

.accommodation-title {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .ac-for-mobile {
        position: absolute;
        right: 5%;
    }

    .btn {
        border-radius: 6px;
    }
}

.new-inline {
    background-color: #ffff;
    border-radius: 10px;
}

.bullet-point {
    margin-bottom: 20px;
    li {
        display: flex;
        align-items: center;
        float: left;
        margin: 5px; 
        box-sizing: border-box; 
        font-family: "Catamaran", Arial, Helvetica, sans-serif;

        @media (min-width: $screen-tablet) {
                width: calc(33.33% - 10px);
        }

        @media (max-width: ($screen-tablet - 1)) {
            width: 100%;
        }

        &.checked {
            color: #3c763d;
        } 

        &.unchecked {
            color: #ed1b24;
        }

         &.with-information:after {
            content: "\f05a";
            font: normal normal normal 14px/1 FontAwesome; 
            margin-left: 10px;
            font-weight: 900; /* Specify the font weight for the icon */
            font-style: normal; 
            color: #01a2db;
            cursor: pointer;
        }

        .fa-circle {
            margin-right: 5px;
        }

    }

    &.bullet-price {
        ul {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px;
        }
    }
}

.accommodation-payment {
    display: flex;
    flex-direction: column;

    h3 {
        color: #01A2DB;
    }


    .btn {
        margin-left: auto;
        min-width: 150px;
        width: 25%;
    }
}

.accommodation-price, .accommodation-recap, .accommodation-check-list, .service-check-list, .ff-check-list {
   
    &.accommodation-price, &.accommodation-check-list, &.ff-check-list {
         background-color: #f2fafd;
    }
    &.accommodation-recap {
        border: #7D939B solid 1px;
    }

    margin-top: 10px;
    padding: 10px;
    border-radius: 10px;

    &.service-check-list {
        box-shadow: 0 3px 5px $body-bg;
        padding: 16px;
        margin-top: 0;
        border-radius: 0;
    }

    h3 {
        color: #01A2DB;
    }

    .accommodation-price-item {
        margin-bottom: 15px;
    }

    .accommodation-price-value {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .accommodation-price-title {
            white-space: nowrap;
            margin-right: 10px;
        }

        i {
            color: #01A2DB;
            cursor: pointer;
        }
    }

    .accommodation-price-comment {
        display: flex;
        flex-direction: row;
        align-items: center;

        i {
            margin-right: 10px;
            color:#01A2DB;
        }
    }

    .accommodation-more-information {
        p {
            margin-left: 0 !important;
            margin-right: 0 !important;
        }
    }
}

.tab-item, .row.new-inline {
    margin: 5px;

    .book-now {
         @media (max-width: ($screen-tablet - 1)) {
            z-index: inherit;
        }
    }

    .ac-inline-container {
        max-width: 100%;
        overflow: hidden;
    }

    /* Apply the styling to iframes within the container */
    .ac-inline-container iframe {
        width: 100%;
       border: none;
        box-sizing: border-box;
    }
}

.accommodation-content {
    display: flex;
    flex-direction: row-reverse;
    .accommodation-info {
        flex: 1
    }
    .accommodation-tab {
        flex: 2
    }

    @media (max-width: ($screen-lg-desktop - 1)) {
        flex-direction: column;
    }
}

.accommodation-header {

    background-color: white;
    border-radius: 10px;
    padding: 20px;
    // display: flex;

    .residence-presentation-slider {
        width: 460px !important;  
        overflow: visible;  

        @media (max-width: 500px) {
            width: 250px !important;
        }
    }


    .carousel-container {
        display: flex;
        align-items: flex-start;
        justify-content: center;

        .media-miniature {        
            .image-miniature {
                flex-basis: 32%;
                margin: 0 1% 1% 0;

                img {
                    width: 100%;
                }
            }   

            @media (min-width: $screen-desktop) {
                display: flex;
                max-height: 290px; 
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: flex-start;
                align-items: center;
                overflow-y: auto;
            }


            @media (max-width: ($screen-desktop - 1)) {
                display: none;
            }
        }
    }
}


.accommodation-filter {

    .accommodation-filter-action {
        display: flex;
        align-items: center;
        justify-content: center;
        .btn {
            min-height: 45px;
        }
    }

    .sort-of {
        display: flex;
        align-items: center;
        .sort-btn {
            margin-left: 5px;
            min-width: 40px;
            min-height: 40px;
        }
    }

    .select2-search__field {
       display: none;
    }

    .range-prices {
        background-color: #fff;
    }

    .control-label {
        color: #01A2DB;
        font-weight: 700;
        font-family: 'Catamaran';
    }

    .modal-body {
        h6 {
            color: #7D939B;
        }    
        .form-group {
            border-bottom: 1px solid #E4E6E8;
            margin-bottom: 20px;
        }
    }

    .range-slider {
        height: 5px;
        position: relative;
        background-color: #7D939B;
        border-radius: 2px;
    }

    .input-euro {
        display: flex;
        align-items: center;

        &:after {
            content: '€'; 
            margin-right: 10px;
        }

        input {
            flex: 1;
            padding: 10px;
            border: 1px solid #ccc;
        }
    }

    
    .range-selected {
        height: 100%;
        left: 0%;
        right: 100%;
        position: absolute;
        border-radius: 5px;
        background-color: #7D939B;
    }

    .range-input {
        position: relative;

        input {
            position: absolute;
            width: 100%;
            height: 5px;
            top: -7px;
            background: none;
            pointer-events: none;
            -webkit-appearance: none;
            -moz-appearance: none;

            &::-webkit-slider-thumb {
                height: 20px;
                width: 20px;
                border-radius: 50%;
                border: 3px solid #172E36;
                background-color: #172E36;
                pointer-events: auto;
                -webkit-appearance: none;
            }

            &::-moz-range-thumb {
                height: 15px;
                width: 15px;
                border-radius: 50%;
                border: 3px solid #172E36;
                background-color: #172E36;
                pointer-events: auto;
                -moz-appearance: none;
            }
        }
    }

    .range-price {
        margin: 30px 0;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        label {
             margin-right: 5px;
        }

        div {
            border-style: solid;
            border-radius: 10px;
            width: 50%;
            border-width: thin;
            padding: 5px;
            margin: 5px;

            input {
                width: 100%;
                border: none;
                text-align: right;
                padding: 5px;
                font-weight: bolder;
            }

        }
    }

    .inner-addon {
        position: relative;
        .glyphicon {
            position: absolute;
            padding: 10px;
            pointer-events: none;
            color: #01a2db;
        }
    }

    .left-addon {
        .glyphicon {
            left: 0
        }
        input {
            padding-left:  30px; 
        }
    }
    .right-addon {
        .glyphicon {
            right: 0
        }
        input {
            padding-right:  30px; 
        }
    }

    .btn-filter {
        margin-top: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .btn {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 6px;

        .btn-icon-right {
            margin-right: 10px;
        }

        .btn-icon-left {
            margin-left: 10px;
        }
    }

    .btn-outline-primary {
        border: #01A2DB 1px solid;
        color:  #01A2DB;
    }

    // for guarantor
    .chk-with-information {
        label:after{
            content: "\f05a";
            font: normal normal normal 14px/1 FontAwesome; 
            margin-left: 10px;
            font-weight: 900;
            font-style: normal; 
            color: #01a2db;
            cursor: pointer;
        }
    }

    // for short stay
    .condition-of-acceptance {
        label[for="form_conditionOfAcceptance_3"]:after {
            content: "\f05a";
            font: normal normal normal 14px/1 FontAwesome; 
            margin-left: 10px;
            font-weight: 900;
            font-style: normal; 
            color: #01a2db;
            cursor: pointer;
        }
    }

}

.ac-content {
    padding: 15px;
}
.af-notify-title {
    margin-right: 10px;
}
.af-notify-title,
.af-notify {
    margin-top: 4px;
    margin-bottom: 8px;
    display: inline-block;
}
.ac-header,
.ac-body {
    background-color: #F2FAFD;
}
.ac-footer {
    background-color: #fff;
}
.ac-body {

    .fc-label {
        font-size: 16px;
        color: $brand-dark;

        svg {
            float: right;
        }
    }
}

.congrat-item {
    margin-bottom: 25px;
    border-radius: 6px;
    background-color: #fff;
    box-shadow: $block-shadow;

    .media-body {
        vertical-align: middle;
        padding: 10px 35px 10px 40px;
    }
}
.ci-item {
    display: inline-block;
    margin-bottom: 4px;
}
.ci-title {
    margin-bottom: 0;
}

.ci-tag {
    float: right;
    margin-top: 4px !important;
    padding: 7px 14px 5px;
    border-radius: 16px;
    font-size: 14px;
    background-color: #f6f7f7;

    strong {
        color: $brand-dark;
    }
}

.accommodation-flatmate-box {
    label {
        font-size: 16px;
    }
}

@media (min-width: $screen-tablet) {
    .ac-body {

        .fcw2 {
            border-left: 0;
        }
    }

    .ac-for-mobile {
        display: none;
    }
    .ac-for-web {
        display: block;
    }

}
@media (min-width: $screen-xlg-desktop) {

    .ci-item {
        margin-top: 10px;
    }
    .ci-text {
        margin-right: 30px;
        margin-left: 20px;
    }
    .accomodation-form {

        .if-col-select {
            width: 15%;
        }
        .if-col-input {
            width: 85%;
        }
    }
    .col-xlg-6 {
        float: left;
        width: 50%;
        position: relative;
        min-height: 1px;
        padding-right: 15px;
        padding-left: 15px;
    }
}

@media (max-width: ($screen-lg-desktop - 1)) {
    .ci-tag {
        float: none;
    }
    .accomodation-card {

        .accomodation-item {

            .media-left,
            .media-body {
                display: block;
                padding-left: 0;
                padding-right: 0;
            }
            .media-left {
                padding-bottom: 20px;
                text-align: center;

                img {

                    @include img-responsive(inline-block);
                }
            }
            .media-body {
                width: auto;
            }
            .btn-lg {
                margin-top: 15px;
            }
        }
    }
}

@media (max-width: ($screen-desktop - 1)) {
    .ci-item {
        display: block;
        margin-right: 0;
        text-align: center;
    }
    .congrat-item {

        .media-body {
            width: auto;
        }
    }
}
@media (max-width: ($screen-tablet - 1)) {
    .ac-body {

        .fcw2 {
            border-top: 0;
        }
    }

    .accommodation-details {
        z-index: 1 !important;
    }


    .ac-for-mobile {
        display: block;
    }
    .ac-for-web {
        display: none;
    }


}
@media (max-width: 612px) {

    .congrat-item {

        .media-left {

            img {
                width: 100px;
            }
        }
        .media-body {
            display: block;
            padding: 10px;
        }
        .media-left {
            display: block;
            padding-right: 0;
            text-align: center;
        }
    }
    .ci-item {
        display: block;
        margin-right: 0;
        text-align: center;
    }
    .ci-tag {
        display: block;
    }
    .accomodation-form.alert,
    .accomodation-card .alert-danger {
        margin-left: -10px;
        margin-right: -10px;
    }

    .accomodation-form {

        .if-col-select {
            width: 35%;
        }
        .if-col-input {
            width: 65%;
        }
    }

    .accomodation-slider {
        width: 100px;
    }

    .ai-desc {
        display: none;
    }

    .ai-item-list {
        margin-top: 0;
        .time-to-school {
            display: none;
        }
    }
    .ai-title {
        margin-bottom: 0;
        h3 {
            margin-bottom: 0;
        }
    }

}
