/*
    General
    ========================================================================== */

.btn {
    white-space: inherit;
    @include transition(all ease .4s);
    @include clearfix();

    &, &:hover, &:focus {
        border-color: transparent;
    }
    &.has-btn-icon-left {

        i, svg {
            margin-right: 4px;
        }
    }
    &.has-btn-icon-right {

        i, svg {
            margin-left: 4px;
        }
    }
}

.btn-icon-right {
    float: right;
}

.btn-arrow {
    min-width: 180px;

    .btn-icon-right {
        display: inline-block;

        svg {
            display: inline-block;
            vertical-align: middle;
            height: 10px;
        }
    }
}

.btn-outline-darker{
    @include button-outline-variant($brand-black);
    border-radius: 8px;
    font-weight: 700;
    display: flex;
    align-items: center;

    svg{
        width: 18px;
        height: 18px;
    }
}

.btn-dark {
    @include button-variant(#fff,$brand-dark,$brand-dark);
}

.btn-primary-light {
    background-color: #ebfaf4;
    color: $brand-primary !important;
    border-radius: 6px;

    &:hover,
    &:focus {
        background-color: darken(#ebfaf4,5%);
    }

    svg {
        position: relative;
        top: 2px;
    }
}
.btn-white-secondary {
    background-color: #fff;
    color: $brand-secondary;

    svg {
        position: relative;
        display: inline-block;
        vertical-align: middle;
        top: -1px;
    }

    &:hover,
    &:focus {
        background-color: $brand-secondary;
        color: #fff;

        svg {

            use {
                fill: #fff;
            }
        }
    }
}

.btn-blue {
    background-color: $brand-secondary;
    color: $white;
    &:hover,
    &:active,
    &:focus {
        outline: none;
        background-color: darken($brand-secondary, 5%);
        color: #fff;
        svg {
            use {
                fill: #fff;
            }
        }
    }
}
.paper-guide-vertical {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 1px solid #e3e6e6;
    border-radius: 6px;
    margin-top: 20px;
    padding: 20px;
    width: 50%;
    background: 100% 0 image-url('border-book.png') no-repeat;
    text-decoration: none !important;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}

.paper-guide-vertical-or {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 20px 0 20px 0;

    &:before, &:after {
    content: "";
    flex: 1 1;
    border-bottom: 1px solid;
    margin: 10px;
    font-size: 12px;
    line-height: 40px;
    }
}

.paper-guide {
    position: relative;
    display: inline-block;
    padding: 10px 40px 10px 20px;
    border: 1px solid #e3e6e6;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    background: 100% 0 image-url('border-book.png') no-repeat;
    text-decoration: none !important;

    &:hover {

        strong {
            color: $brand-primary
        }
    }

    .btn {
        display: block;
        margin-top: 10px;
        font-weight: bold;
    }
    strong {
        display: block;
        padding-left: 4px;
        margin-top: 35px;
        color: $brand-dark;
        transition: all ease .4s;
    }
}

.btn-step-navigation {
    color: $brand-dark;
    border: 1px solid $brand-dark;
    height: 48px;
    padding: 12px 18px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 105px;

    &.disabled {
        pointer-events: none;
        color: $button-disabled-color;
        border-color: $button-disabled-color;

        svg {
            path {
                fill: $button-disabled-color;
            }
        }
    }

    &:hover, &:active, &:focus {
        border-color: inherit;
        color: inherit;
    }

    svg {
        height: 24px;
        margin-bottom: -5px;
    }

    &.icon-right {
        svg {
            margin-left: 5px;
        }
    }

    &.icon-left {
        svg {
            margin-right: 5px;
        }
    }
}

.btn-ellipse-qa{
    width: 75px;
    height: 49px;
    background-color: #172E36;
    border-radius: 50%;
    svg{
        margin-top: 12px;
        margin-left: 20px;
    }
}


/*
    Responsive
    ========================================================================== */

@media (min-width: $screen-tablet) {

    .paper-guide {
        height: 230px;
        margin-left: 30px;

        .btn {
            position: absolute;
            left: 10px;
            right: 20px;
            bottom: 15px;
        }
    }
    .or-line {
        position: relative;

        &:before {
            content: '';
            display: block;
            @include size(1px,100%);
            background-color: #e3e6e6;
            position: absolute;
            left: -30px;
            top: 0;
            bottom: 0;
        }
        &:after {
            content: 'or';
            position: absolute;
            @include size(15px,40px);
            left: -38px;
            top: 50%;
            margin: -20px auto 0;
            bottom: 0;
            text-align: center;
            background-color: #fff;
            font-size: 12px;
            line-height: 40px;
            color: $text-color !important;
        }
    }
}
@media (max-width: 512px) {

    .btn-row-xs {

        > div {
            float: none;
            width: 100%;

            .btn {
                display: block;
                margin-bottom: 10px;
            }
        }
        .text-right-xs {
            text-align: left;
        }
    }

    .text-right-sm {

        .btn {
            box-sizing: border-box;
            width: 100%;
            margin: 20px;
        }
    }

}

.btn-darker{
    background: $color-gray-darker;
    border-radius: 8px;
    font-weight: 700;
    color: white;

    &:hover,
    &:focus {
        background-color: white;
        border-color: $color-gray-darker;
        color: $color-gray-darker;
    }

    svg{
        width: 18px;
        height: 18px;
    }
}

