.form-row {
    width: 100%;
}

.form-control-wr {
    padding: 16px;
    background-color: #fff;
    border: 1px solid $input-border;

    + .form-control-wr {
        border-top: 0;
    }

    .form-control {
        border: 0;
        padding-left: 0;
        padding-right: 0;

        &:focus {
            border: 0;
            box-shadow: none;
        }
    }
}
.form-control {
    border-radius: 6px;
    box-shadow: none;
}
.fc-label {
    color: #ccd1d3;
    font-size: 14px;
}
label {
    font-size: 14px;
    font-weight: 400;
    color: $label-color;
    a {
        color: $text-color;
    }
}
.fc-label-link {
    color: $text-color;
    font-size: 16px;
}
.form-section {
    margin-bottom: 35px;
    padding-top: 20px;

    .fs-title {
        position: relative;
        padding: 8px 0;

        &:before {
            content: '';
            display: block;
            @include size(4px,100%);
            position: absolute;
            left: -29px;
            top: 0;
            background-color: $headings-color;
        }
    }
}

.input-group-left {

    .input-group-addon {
        border-right: 0;
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
    input.form-control {
        border-left: 0;
        border-top-right-radius: 6px !important;
        border-bottom-right-radius: 6px !important;
    }
}
.input-group-right {

    .input-group-addon {
        border-left: 0;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;

        svg {
            display: inline-block;
            vertical-align: middle;
        }
    }
    input.form-control {
        border-right: 0;
        border-top-left-radius: 6px !important;
        border-bottom-left-radius: 6px !important;
    }
}
.input-group-date {

}

.input-group-date, .input-group-date-custom {
    position: relative;

    .icon-group-date {
        position: absolute;
        top: 1px;
        right: 1px;
        width: 40px;
        text-align: center;
        height: ($input-height-base - 2);
        line-height: $input-height-base + 3;

        &:hover {
            cursor: pointer;

            svg {
                fill: $brand-primary;
            }
        }
    }
}

::-moz-placeholder {
    font-size: 16px;
}
:-ms-input-placeholder {
    font-size: 16px;
}
::-webkit-input-placeholder {
    font-size: 16px;
}


/*
   Form upload
   ========================================================================== */

.form-upload-container {
    position: relative;
    display: table;
    @include size(100%,113px);
    background-color: #fff;
    cursor: pointer;

    &.fuc-vertical {
        @include size(100%,340px);

        .fu-cell-content {
            height: 324px;
        }
    }

    .fucc-delete-wr {
        display: none;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        @include square(100%);
        background-color: rgba($brand-dark,.7);
        border-radius: 6px;
    }
    .fucc-delete {
        position: absolute;
        top: 50%;
        margin: -28px auto 0;
        left: 0;
        right: 0;
        @include square(56px);
        background-color: #fff;
        border-radius: 50%;
        text-align: center;
        line-height: 70px;

        .svg-trash {
            stroke: $brand-dark;
        }
    }

    &:hover {

        .svg-drop {
            stroke: lighten($brand-dark,10%);
        }
        label {
            cursor: pointer;
            color: lighten($brand-dark,10%);
        }
        .fucc-delete-wr {
            display: block;
            @include fadeIn();
        }
    }
}

.document_example_preview {
    padding: 7px;
    background-color: #fff;
    border: 1px solid #d1d5d7;
    border-radius: 6px;
    margin-bottom: 10px;
}

.fu-error {
    background-color: #fdf0f2;

    .fu-cell {

        label {
            color: #ee8998;
        }
    }
}
.fu-cell {
    display: table-cell;
    @include square(100%);
    vertical-align: middle;
    text-align: center;

    label {
        display: block;
        font-size: 16px;
        color: $brand-primary;
        margin-top: 10px;
    }

    .rotate {
        margin-top: 20px;
    }
}

.dz-drag-hover {
    border: 2px dashed $brand-primary;
}

.fu-cell-content {
    display: table-cell;
    @include size(100%,95px);
    //vertical-align: bottom;
    vertical-align: middle;
    text-align: center;
    position: relative;
    border: 1px solid #d7d8dc;
    background-color: #f2eff3;
    border-radius: 6px;

    img {
        @include img-responsive(inline-block);
    }
}
.fuc-status {
    position: absolute;
    left: 5px;
    bottom: 5px;
    padding: 4px 10px;
    color: #fff;
    font-size: 12px;
    text-transform: uppercase;
    border-radius: 6px;
}
.fuc-success {
    background-color: $brand-primary;
}
.fuc-danger {
    background-color: #e4425a;
}
.fuc-danger-text {
    margin: 10px 0;
    text-align: center;
    font-size: 14px;
    color: #e4425a;
}
.fuc-pending {
    background-color: $brand-dark;
}

.form-upload-filename {
    @include clearfix();
    padding: 15px;
    border-radius: 6px;
    font-size: 16px;
    color: $brand-dark;
    font-weight: 700;

    span {
        display: inline-block;
        margin-top: 4px;
        margin-right: 15px;
    }
    a {
        display: inline-block;
        line-height: 1;
        margin-top: 4px;
        float: right;

        &:hover {
            opacity: .6;
        }
    }

    .file-info {
        display: flex;
        flex-direction: column;

        .filename {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .url-name {
                flex: 1;
                text-overflow: ellipsis;
                margin-left: 16px;
                margin-right: 16px;
                text-align: center;
            }
        }

        .state {
            text-align: center;
        }
    }
}

.docref-option-document {
    display: flex;
    
    .docref-option-container {
        margin-top: 20px;
        .docref-option, .docref-extra {
            .form-upload-filename {
                .url-name {
                    // display: none;
                }
            }
        }
    }
}

.uploader-shadow {
    box-shadow: 0 3px 5px $body-bg;

    &.visa-upload {
        max-width: 300px;
    }
}

.question-hover-item {
    position: relative;

    .question-trigger {
        cursor: pointer;
        display: inline-block;

        .advice-content {
            border: 1px solid grey;
            padding: 7px;
            background: white;
            position: absolute;
            font-weight: normal;
            z-index: 1;
            top: 20px;
            right: 0;
            display: none;
        }
    }
}

.user-profile-uploader {
    display: flex;
    height: 60px;

    .left-part {
        width: calc(100% - 55px);
        text-align: right;
        padding-right: 30px;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;

        label {
            font-size: 16px;
            font-weight: 600;
            color: #172e36;
            width: 100%;
        }

        .fileuploads {
           font-size: 13px;
            font-weight: 600;
            color: #172e36;
            border-bottom: 2px dashed #a5a4c4;
            width:110px;
        }
    }
    .right-part {
        width: 55px;
    }

    .form-upload-container {
        border: none;
        height: auto;
        padding: 0;
    }

    .form-upload-filename {
        border-radius: 50%;
        padding: 0;
        img {
            border-radius: 50%;
            width: 55px;
            height: 55px;
        }
        img + div {
            position: absolute;
            right: 15px;
            bottom: 25px;
            a {
                width: 25px;
                height: 25px;
                background: #000;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;

                svg {
                    width: 11px;
                }
            }
        }
    }

    .user-profile-progress-bar {
        visibility: hidden;
        width: 0;
        height: 0;
        opacity: 0;
        margin: 0;
    }
}

/*
   Radio, Checkbox input
   ========================================================================== */

.ckbox,
.rdiobox {
    font-weight: normal;
    position: relative;
    display: block;
    line-height: $ckbox-rdio-label-line-height;

    label {
        position: relative;
        padding-left: 24px;
        color: $label-color;
        font-size: 16px;
        font-weight: 400;
        cursor: pointer;

        > span {
            vertical-align: middle;

            &:before,
            &:after {
                content: '';
                position: absolute;
                line-height: $ckbox-size;
            }
            &:after {
                display: none;
            }
        }
    }

    input {
        position: absolute;
        @include opacity(0);
        margin: 0 5px 0 0;

        &:focus + span:before {
            @include box-shadow(0 0 3px $ckbox-rdio-focus-color);
        }
        &[disabled] {

            + span,
            + span:before,
            + span:after {
                @include opacity(.75);
            }
        }
        &:checked {

            + span {
                color: $brand-dark;

                &:after {
                    display: block;
                }
            }
        }
    }
}

.rdiobox-inline,
.ckbox-inline {
    display: inline-block;
}

.form-inline {

    .ckbox,
    .rdiobox {
        display: inline-block;

        > label {
            margin-right: 10px;
        }
    }
}


//== Checkbox
.ckbox {

    label {

        > span {
            line-height: 26px;

            &:before,
            &:after {
                @include square($ckbox-size);
                line-height: $ckbox-size;
                border-radius: 3px;
            }

            &:before {
                border: 1px solid $ckbox-default-border-color;
                background-color: $ckbox-default-bg;
                top: 3px;
                left: 0;
                border-radius: 6px;
            }
            &:after {
                left: 0;
                top: 3px;
                content: svg-url('check.svg');
                font-size: $ckbox-checked-font-size;
                color: $brand-primary;
                text-align: center;
            }

            .ckbox-primary & {

                &:before {
                    //border-color:$brand-primary;
                }
            }
        }
    }

    input {

        &:checked {

            + span {

                &:before {
                    background-color: $ckbox-checked-bg;
                    border-color: $ckbox-default-checked-color;
                }
            }
        }
    }
    .ckbox-primary & {

        input:checked {

            + span {

                &:before {
                    border-color: $brand-primary;
                }
            }
        }
    }
}

//== Radio Box
.rdiobox {

    label {

        > span {
            line-height: 23px;

            &:before,
            &:after {
                line-height: $rdiobox-size;
                border-radius: $rdiobox-size;
                background-color: $rdio-default-bg;
            }

            &:before {
                @include square($rdiobox-size);
                border: 1px solid $rdio-default-border-color;
                top: 1px;
                left: 0;
            }
            &:after {
                @include square(8px);
                top: 6px;
                left: 5px;
            }

            .ckbox-primary & {

                &:before {
                    //border-color:$brand-primary;
                }
            }
        }
    }

    input {

        &:checked {

            + span {

                &:before {
                    border-color: $rdio-default-checked-border-color;
                }
                &:after {
                    background-color: $rdio-default-checked-bg;
                }
            }
        }
    }
    .ckbox-primary & {

        input:checked {

            + span {

                &:before {
                    border-color: $brand-primary;
                }
                &:after {
                    background-color: $brand-primary;
                }
            }
        }
    }
}



/*
   Services list
   ========================================================================== */


.services-list {

    label {
        display: block;
        position: relative;
        color: $headings-color;
        font-weight: 400;
        font-size: 16px;
    }

    .media {
        display: table;
        height: 78px;
        margin: 0 0 10px;
        padding: 15px 15px;
        border: 1px solid $brand-gray;
    }
    .media-left,
    .media-body {
        vertical-align: middle;
    }
    .media-left {
        transition: padding linear .4s;

        svg {
            display: inline-block;
            vertical-align: middle;
        }
    }
    .media-body {
        padding-left: 4px;
        line-height: 1;
        transition: color linear .5s;
    }

    input {
        visibility: hidden;
        position: absolute;

        &:checked {

            + .media {
                border: 1px solid $brand-primary;

                .media-left {
                    padding-left: 10px;
                }
                .media-body {
                    color: $brand-primary;
                }
            }
        }
    }
}

.si-no-border {

    .media {
        border: 0 !important;
    }
}

.gender-radiobox {
  display: inline-block;
  position: relative;
  padding-left: 30px;
  margin-right: 10px;
  cursor: pointer;
  user-select: none;
  
  input[type="radio"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  &.danger {
    &::before {
        border: 2px solid $brand-red;
        box-shadow: 0 0 5px 0px $brand-red;
    }
    &::before {
        border: 2px solid $brand-red;
    }
  }

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    border: 2px solid $brand-dark-gray;
    border-radius: 50%;
    background-color: $white;
    box-shadow: none; // No shadow by default
    transition: box-shadow 0.2s ease;
  }

  input[type="radio"]:checked + span::before {
    background-color: $white;
  }

  input[type="radio"]:checked + span::after {
    content: '';
    position: absolute;
    left: 6px;
    top: 6px;
    width: 8px;
    height: 8px;
    background-color: $brand-dark-gray;
    border-radius: 50%;
  }
}


/*
   BPH prices radio blocks
   ========================================================================== */


.price-block {
    display: block;
    position: relative;
    cursor: pointer;

    input {
        visibility: hidden;
        position: absolute;

        &:checked {

            + .pb-wr {
                background-color: #effbf7;
                border-color: $brand-primary;
            }

            + .logo-choice img {
                border: 4px solid $brand-primary;
                padding: 10px;
            }
        }
    }
    .pb-wr {
        display: block;
        padding: 15px;
        background-color: #fff;
        border: 2px solid #f6f7f7;
        border-radius: 6px;

        strong {
            display: block;
            margin-bottom: 12px;
            font-size: 18px;
            font-weight: 700;
            color: $headings-color;
        }

        span {
            font-size: 12px;
        }
    }
    &.dont-want-service {
        input {
            &:checked {
                + .pb-wr {
                    background-color: #fbefef;
                    border-color: $brand-red !important;
                }
            }
        }
    }
}




.select2-container .select2-selection--single,
.select2-container--default .select2-selection--single .select2-selection__arrow {
    outline: 0 !important;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
    color: $brand-primary;
    font-weight: 700;
}

.input-flag-group {

    .select2-container--default .select2-selection--single,
    .select2-dropdown,
    .select2-container--default .select2-search--dropdown .select2-search__field {
        border-left: 0;
        border-right: 0;
        border-radius: 0;
        background-color: $input-group-addon-bg;
    }
    .select2-results, .select2-results__option,
    .select2-container--default .select2-selection--single .select2-selection__rendered,
    .select2-container--default .select2-selection--single .select2-selection__rendered {
        color: $brand-dark;
        font-weight: 700;
    }
    input.form-control {

        &:focus {
            border-color: $input-border;
            outline: 0;
            box-shadow: none;
        }
    }
}

.well-upload-container {
    background-color: #F2FAFD;
    padding: 20px;
}

.dropper {
    &.disabled {
        user-select: none;
    }
}

.stripped-upload-container {
    width: 70%;
    border: 3px;
    border-color: #E4E6E8;
    padding: 0;
    border-radius: 10px;
    border-style: dotted;
    margin: 0 auto;
    margin-top: 20px;
}

@media (min-width: $screen-phone) and (max-width: $screen-tablet - 1) {

    .if-col-select {
        width: 15%;
    }
    .if-col-input {
        width: 85%;
    }
}
@media (min-width: $screen-tablet) {

    .form-group-s {

        .form-group {
            margin-bottom: 5px;
        }
    }
    .price-block {

        .pb-wr {
            padding-top: 25px;
            // height: 150px;
        }
    }
}
@media (min-width: $screen-desktop) {

    .form-group {
        margin-bottom: 30px;
    }
}
@media (min-width: $screen-lg-desktop) {


}
@media (min-width: $screen-xlg-desktop) {


}

@media (max-width: $screen-tablet - 1) {


}

@media (max-width: 612px) {

    .input-flag-group {

        .input-group-addon {
            padding-right: 5px;
        }
    }
}
@media (max-width: $screen-phone) {
    .dropzone {
        .file-info .url-name {
            display: none;
        }
    }

}
